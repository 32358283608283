import { rnd, ucFirst } from '../../utils';
import { format } from '../../utils/date';

export function makeDeploymentCode(id = '') {
	return `<!-- Mopinion Pastea.se  start -->
	<script type="text/javascript">(function(){var id="${id}";var js=document.createElement("script");js.setAttribute("type","text/javascript");js.setAttribute("src","https://deploy.mopinion.com/js/pastease.js");js.async=true;document.getElementsByTagName("head")[0].appendChild(js);var t=setInterval(function(){try{Pastease.load(id);clearInterval(t)}catch(e){}},50)})();</script>
	<!-- Mopinion Pastea.se end -->`;
}

export function operators(type) {
	switch (type) {
		case 'location':
		case 'referrer':
			return [
				{ value: 'exists', label: 'URL contains' },
				{ value: 'does not exist', label: 'URL does not contain' },
			];

		case 'cookie':
		case 'css_selector':
			return [
				{ label: 'exists', value: 'exists' },
				{ label: 'does not exist', value: 'does not exist' },
				{ label: 'contains', value: 'contains' },
				{ label: 'matches exactly', value: 'matches exactly' },
				{ label: 'does not contain', value: 'does not contain' },
			];

		case 'js':
			return [
				{ label: 'exists', value: 'exists' },
				{ label: 'does not exist', value: 'does not exist' },
				{ label: 'contains', value: 'contains' },
				{ label: 'matches exactly', value: 'matches exactly' },
				{ label: 'does not contain', value: 'does not contain' },
				{ label: 'is boolean', value: 'is boolean' },
			];

		case 'clock':
		case 'date':
			return [
				{ label: 'before', value: 'earlier' },
				{ label: 'exactly', value: 'exactly' },
				{ label: 'after', value: 'later' },
				{ label: 'between', value: 'between' },
			];

		case 'type':
			return [
				{ label: 'new', value: 'new' },
				{ label: 'returning', value: 'returning' },
			];

		case 'number_of_pages':
			return [
				{ label: 'at least', value: 'min' },
				{ label: 'at most', value: 'max' },
			];
		case 'scrollpos':
			return [
				{ label: 'top', value: 'top' },
				{ label: 'bottom', value: 'bottom' },
			];
		default:
			return [];
	}
}

export function innerConditionBase(type) {
	switch (type) {
		case 'location':
		case 'referrer':
			return { value: '', operator: 'exists', regex: false };

		case 'js':
		case 'cookie':
		case 'css_selector':
			return { name: '', value: '', operator: 'exists' };

		case 'date':
			return { operator: 'earlier', date: format(new Date(), 'dd/MM/yyyy'), date2: '' };

		case 'clock':
			return { operator: 'earlier', time: '12:00', time2: '' };

		case 'scrollpos':
			return { from: 'top', px: 0 };

		case 'number_of_pages':
			return { check: 'min', amount: 1 };
	}
}

export function ruleBase({
	surveyKey,
	domain,
	editorType = 'web',
	surveyType = 'web',
	surveyFormat = 'form',
	webview = false,
}) {
	return {
		id: rnd(),
		if: [conditionBase(editorType)],
		then: [
			{
				service: 'mopinion',
				args: [surveyKey, domain, 'surveyContent'],
				...(editorType === 'sdk'
					? {
							webview:
								webview || surveyType === 'web' || surveyFormat === 'conversational',
					  }
					: {
							disableMakeDiv: false,
							useQuerySelectorAll: false,
					  }),
			},
		],
	};
}

export function conditionBase(editorType) {
	return editorType === 'sdk'
		? {
				trigger: 'passive',
				mobile: {
					events: ['_button'],
					target: [],
				},
				percentage: null,
				time: 0,
				date: {},
				clock: {},
				session: 30,
		  }
		: {
				trigger: 'passive',
				percentage: null,
				time: null,
				time_in_session: null,
				location: [],
				js: [],
				cookie: [],
				referrer: [],
				scrollpos: {},
				number_of_pages: {},
				type: null,
				devices: [],
				session: 30,
				css_selector: [],
				date: {},
				clock: {},
		  };
}

export function valueLabel(value, key) {
	switch (value) {
		case 'passive':
			return 'by button';
		case 'exit':
			return 'on page leave';
		case 'proactive':
			return 'proactively';
	}

	switch (key) {
		case 'percentage':
			return `% of users`;
	}
}

export function triggerLabelSDK(trigger) {
	switch (trigger) {
		case 'passive':
			return 'open on every event';
		case 'proactive':
			return 'open once per event';
	}
}

export function conditionTypeLabel(type, value) {
	switch (type) {
		case 'trigger':
			return ['Trigger', valueLabel(value)];

		case 'events':
			return ['Event fires with name', value.join(', ')];

		case 'percentage':
			return [`${value}%`, 'of users'];

		case 'time':
			return ['After', value, 'seconds on page'];

		case 'time_in_session':
			return ['After', value, 'seconds on website'];

		case 'location':
			//actual operator requires 'exists' but should show 'contains' in view to avoid confusion for users
			return [
				'Page URL',
				...innerArrayConditionLabel(value).map(x =>
					x === 'does not exist' ? 'does not contain' : x === 'exists' ? 'contains' : x
				),
			];

		case 'js':
			return ['JavaScript variables', ...innerArrayConditionLabel(value)];
		case 'cookie':
			return ['Cookies', ...innerArrayConditionLabel(value)];

		case 'referrer':
			return value.length && typeof value[0] === 'string'
				? ['Previous page URL contains', ...value]
				: [
						'Previous page URL',
						...value.map(
							(x, index) =>
								`${index !== 0 ? 'or' : ''} ${
									x.operator === 'exists' ? 'contains' : 'does not contain'
								} ${x.value}`
						),
				  ];

		case 'scrollpos':
			return [
				'Scroll position is',
				String(value.px).indexOf('%') === -1 ? `${value.px}px` : value.px,
				'from the',
				value.from,
			];

		case 'number_of_pages':
			return [
				'After visiting',
				...(value.check === 'min' ? ['a minimum of'] : ['a maximum of']),
				value.amount,
				'pages',
			];

		case 'type':
			return [ucFirst(value), 'visitors only'];

		case 'devices':
			return ['Only on', ...value, 'devices'];

		case 'session':
			return ['Refresh session after', value, 'days'];

		case 'css_selector':
			return ['HTML elements', ...innerArrayConditionLabel(value)];

		case 'date':
			return [
				'Date is',
				value.operator,
				value.date,
				...(value.operator === 'between' ? ['and', value.date2] : []),
			];

		case 'clock':
			return [
				'Time is',
				value.operator,
				value.time,
				...(value.operator === 'between' ? ['and', value.time2] : []),
			];

		case 'target':
			return [
				'OS is',
				...value.flatMap((osObj, i) => {
					return [
						osObj.os,
						'with version',
						osObj.version.join(', '),
						...(i !== value.length - 1 ? ['or'] : []),
					];
				}),
			];

		default:
			return [];
	}
}

export function innerArrayConditionLabel(arr) {
	return arr.flatMap((innerArr, arrIndex) => {
		return Array.isArray(innerArr) && innerArr.length > 0
			? [
					...(arrIndex !== 0 && arrIndex !== arr.length ? ['or'] : []),

					...innerArr.flatMap((obj, innerArrIndex) => {
						return [
							...(innerArrIndex !== 0 && innerArrIndex !== innerArr.length
								? ['and']
								: []),
							...(obj.name ? [obj.name] : []),
							obj.operator,
							obj.value,
						];
					}),
			  ]
			: [];
	});
}

export function hasDeploymentValue(value) {
	if (!value) return value;

	if (typeof value === 'string') {
		return value.length > 0;
	}

	if (typeof value === 'number') {
		return !isNaN(value);
	}

	if (typeof value === 'object') {
		if (Array.isArray(value)) {
			return value.length > 0;
		} else {
			return Object.values(value).some(v => v);
		}
	}
}

export function isMultipleCssSelector(str) {
	try {
		const [firstSelector] = String(str).split(' ');

		if (firstSelector.charAt(0).match(/[a-zA-Z]/g)) {
			return (
				document.createElement(firstSelector).toString() !== '[object HTMLUnknownElement]'
			);
		} else if (firstSelector.charAt(0).match(/[.\[]/g)) {
			return true;
		}

		return false;
	} catch (e) {
		return false;
	}
}

function getFormKeyFromRule(rule) {
	return rule?.then?.[0]?.args?.[0];
}

function rulesContainFormKey(rules = [], formKey) {
	return rules.some(rule => {
		return getFormKeyFromRule(rule) === formKey;
	});
}

function mapMissingFormKeys(rulesA = [], rulesB = []) {
	return rulesA
		.map(rule => getFormKeyFromRule(rule))
		.filter(formKey => !rulesContainFormKey(rulesB, formKey));
}

function mapChangedRules(rulesA = [], rulesB = [], addedForms = []) {
	return rulesA
		.filter(rule => {
			const matchingRule = rulesB.find(ruleB => rule.id === ruleB.id);
			//we only want to map rules that still exist
			if (!matchingRule) {
				return true;
			}

			return JSON.stringify(rule) !== JSON.stringify(matchingRule);
		})
		.map(rule => getFormKeyFromRule(rule))
		.filter(formKey => !addedForms.includes(formKey));
}

export function diffDeployRules(newRules, oldRules) {
	const added = mapMissingFormKeys(newRules, oldRules);
	const removed = mapMissingFormKeys(oldRules, newRules);
	const changed = mapChangedRules(newRules, oldRules, added);

	return {
		added,
		removed,
		changed,
	};
}

export function parseDeploymentResponse(deployment = {}) {
	//we want to map old arrays with stringvalues to array with objects
	return {
		...deployment,
		rules: deployment.rules
			? deployment.rules.map((rule, ruleIndex) => {
					return {
						...rule,
						if: rule.if.map((ruleIf, ruleIfIndex) => {
							if (ruleIf.referrer?.length) {
								ruleIf.referrer.forEach((referrer, referrerIndex) => {
									if (typeof referrer === 'string') {
										const newBase = innerConditionBase('referrer');
										newBase.value = referrer.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
										ruleIf.referrer[referrerIndex] = newBase;
									}
								});
							}

							//check if deployment contains old setups with location, cookies, js vars and html content
							//if so normalize them to the current setup
							[
								{ type: 'location', value: ruleIf.location },
								{ type: 'cookie', value: ruleIf.cookie },
								{ type: 'js', value: ruleIf.js },
								{ type: 'css_selector', value: ruleIf.css_selector },
							].forEach(item => {
								const { value = [], type } = item;

								//if locations are defined and the first entry is not an array ->
								//it must be an old setup where the first entry is an object -> check to make sure
								//if so map array of objects to array of nested arrays with objects (OR to AND | OR)
								if (value.length > 0 && !Array.isArray(value[0]) && value[0]?.value) {
									ruleIf[type] = value.map(obj => {
										const base = innerConditionBase(type);
										return [
											{
												...base,
												...obj,
											},
										];
									});
								}
							});

							return ruleIf;
						}),
					};
			  })
			: [],
	};
}
