import React, { Fragment, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';

import ActionIcon from '@material-ui/icons/AssignmentTurnedIn';

import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import get from 'lodash.get';

import { useEFM, useAjaxForm } from '@/components/Ajax';
import { AppBarActions, PageHeader } from '@/components/App';
import { Loader, EmptyState, ActionButton, ActionIconButton } from '@/components/Layout';
import { Pagination } from '@/components/Pagination';
import {
	useFilteredList,
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
} from '@/components/FilteredLists';
import { useAppContext } from '@/components/AppContext';
import ActionExpansionPanel from './ActionExpansionPanel';
import ActionActionDialogs from './ActionActionDialogs';
import ActionListFilters from './ActionListFilters';
import useUpdateActionStatus from './useUpdateActionStatus';
import { SelectionAppBar } from '@/components/SelectionToolbar';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	emptyState: {
		marginTop: '20vh',
		whiteSpace: 'pre-line',
	},
	paginationSummary: {
		margin: 0,
	},
}));

export default function ActionList() {
	const { app } = useAppContext();
	const classes = useStyles();
	const { t } = useTranslation();
	const hasEditPermission = useHasPermission();
	const [state, setState] = useImmer({
		selected: [],
		filtersOpen: true,
		addDialogOpen: false,
		addTagsDialogOpen: false,
		expanded: '',
		updateActionStatus: null,
		updateActionId: '',
	});

	const filterState = useFilteredList({
		filters: {},
		sort: 'created',
		order: 'desc',
	});

	const { filters, other, sort, order, hasFilters } = filterState;

	const { enqueueSnackbar } = useSnackbar();
	const { pageId } = useParams();
	const [actionData, loading, error, loadData] = useEFM(
		`/actions/inbox/list${pageId ? '/page/' + pageId : ''}`,
		{
			filters: {
				...other,
				...filters,
			},
			sort,
			order,
		}
	);

	const selectedAction =
		get(actionData, 'actions', []).find(action => action.id === state.selected[0]) || {};

	const { postForm: postUpdateActionStatus, loading: loadingActionStatus } =
		useUpdateActionStatus({
			status_id: state.updateActionStatus,
			id: selectedAction.id,
			onSuccess: loadData,
		});

	const { postForm: exportPost, loading: exportLoading } = useAjaxForm({
		url: '/actions/inbox/export',
		onSuccess: r => {
			if (r.code == 200) {
				enqueueSnackbar(t`A download link for your export will be sent to your email`);
			} else {
				enqueueSnackbar(t`Something went wrong while generating your export`);
			}
		},
	});

	function updateActionStatus(status) {
		setState(draft => {
			draft.updateActionStatus = status;
		});
	}

	useEffect(() => {
		if (state.updateActionStatus && selectedAction.id) {
			postUpdateActionStatus();
		}
	}, [state.updateActionStatus]);

	function toggleExpand(id, expanded) {
		setState(draft => {
			if (expanded && draft.expanded !== id) {
				draft.expanded = id;
			}

			if (!expanded && draft.expanded === id) {
				draft.expanded = '';
			}
		});
	}
	function setSelected(id, selected) {
		setState(draft => {
			if (selected && draft.selected.indexOf(id) === -1) {
				draft.selected.push(id);
			}

			if (!selected && draft.selected.indexOf(id) > -1) {
				draft.selected.splice(draft.selected.indexOf(id), 1);
			}
		});
	}

	function toggleSelectAll(select) {
		setState(draft => {
			draft.selected = select ? actionData.actions.map(action => action.id) : [];
		});
	}

	function toggleDrawer(set) {
		setState(draft => {
			draft.filtersOpen = typeof set !== 'undefined' ? set : !draft.filtersOpen;
		});
	}

	function setActionDialogs(set) {
		setState(draft => {
			draft.dialogOpen = set;
		});
	}

	return (
		<Fragment>
			<PageHeader title={t`Action overview`} />
			<AppBarActions>
				<NoPermissionTooltip>
					<ActionButton
						action="add"
						size="small"
						variant="text"
						onClick={() => setActionDialogs('add')}
						disabled={!hasEditPermission}
					>
						{t`New action`}
					</ActionButton>
				</NoPermissionTooltip>
			</AppBarActions>

			<ListFilterProvider
				{...filterState}
				singleFilters={['search']}
				multiFilters={['actiontypes', 'actionstatuses', 'assigners', 'assignees', 'tags']}
				filterLabels={{
					assigners: {
						label: t`Owner`,
						...app.users.inProject.reduce((all, id) => {
							const user = app.users.byKey[id];
							//if (user) all[user.role.id] = `${user.firstname} ${user.lastname}`;
							if (user) all[user.id] = `${user.firstname} ${user.lastname}`;
							return all;
						}, {}),
					},
					assignees: {
						label: t`Assignees`,
						...app.users.inProject.reduce((all, id) => {
							const user = app.users.byKey[id];
							//if (user) all[user.role.id] = `${user.firstname} ${user.lastname}`;
							if (user) all[user.id] = `${user.firstname} ${user.lastname}`;
							return all;
						}, {}),
					},
					tags: {
						label: t`Tags`,
						withouttags: t('Without tags'),
					},
					actiontypes: {
						label: t`Action type`,
						...app.actions.types.reduce((all, type) => {
							all[type.id] = type.name;
							return all;
						}, {}),
					},
					actionstatuses: {
						label: t`Action status`,
						...app.actions.status.reduce((all, type) => {
							all[type.id] = t(
								`actions-manage-filters-action_status-${type.name.toLowerCase()}`
							);
							return all;
						}, {}),
					},
				}}
			>
				<ListWithSidebar
					drawerContent={<ActionListFilters />}
					open={state.filtersOpen}
					onClose={toggleDrawer}
				>
					<Grid item>
						<ListControls
							withSelectAll
							onSelectAllChange={e => toggleSelectAll(e.target.checked)}
							selectAllChecked={
								state.selected.length > 0 &&
								state.selected.length >= get(actionData, 'actions', []).length
							}
							onRefresh={loadData}
							toggleFilterDrawer={toggleDrawer}
							sortOptions={[
								{ label: t('Created: new to old'), value: 'created-desc' },
								{ label: t('Created: old to new'), value: 'created-asc' },
								{ label: t('Deadline: nearest to farthest'), value: 'deadline-desc' },
								{ label: t('Deadline: farthest to nearest'), value: 'deadline-asc' },
							]}
						>
							<ActionButton
								action="export"
								onClick={exportPost}
								loading={exportLoading || loading}
								dataTrackEvent="action_action_exported"
							>
								{t('Export as .xls')}
							</ActionButton>
						</ListControls>
					</Grid>

					<Grid item>
						{loading && <Loader empty={get(actionData, 'actions', []).length === 0} />}
						{!loading &&
							Array.isArray(actionData.actions) &&
							actionData.actions.length === 0 && (
								<EmptyState
									className={classes.emptyState}
									image={<img src={`/assets/img/r/emptystate/actions.gif`} />}
									primary={
										hasFilters
											? t`actions-manage-emptystate_filter-title`
											: t`actions-manage-emptystate_default-title`
									}
									secondary={
										hasFilters
											? t`actions-manage-emptystate_filter-text`
											: t`actions-manage-emptystate_default-text`
									}
									action={
										<NoPermissionTooltip>
											<Button
												disabled={!hasEditPermission}
												color="primary"
												variant="contained"
												onClick={() => setActionDialogs('add')}
											>
												{t`actions-manage-emptystate_default-button_create_action`}
											</Button>
										</NoPermissionTooltip>
									}
								/>
							)}
						{Array.isArray(actionData.actions) &&
							actionData.actions.map(actionObj => {
								return (
									<ActionExpansionPanel
										key={actionObj.id}
										action={actionObj}
										onSelection={e => setSelected(actionObj.id, e.target.checked)}
										selected={state.selected.indexOf(actionObj.id) > -1}
										expanded={state.expanded === actionObj.id}
										toggleExpand={(e, expanded) => toggleExpand(actionObj.id, expanded)}
										loadData={() => loadData()}
										listLoading={loading}
									/>
								);
							})}

						{actionData?.actions?.length > 0 && (
							<ExpansionPanel expanded={false}>
								<ExpansionPanelSummary classes={{ content: classes.paginationSummary }}>
									<Pagination
										{...(actionData?.paginator ?? {})}
										base="/actions/inbox"
									/>
								</ExpansionPanelSummary>
							</ExpansionPanel>
						)}
					</Grid>
				</ListWithSidebar>
			</ListFilterProvider>

			<SelectionAppBar
				text={state.selected.length + t(' selected')}
				onClose={e => toggleSelectAll(false)}
				show={state.selected.length > 0}
				fade
				color="default"
				//disableInset={inDrawer}
			>
				{state.selected.length === 1 && (
					<Fragment>
						{selectedAction.owner_user_id === app.users.current.id && (
							<Fragment>
								{selectedAction.status_id == 3 && (
									<ActionButton
										loading={loadingActionStatus}
										onClick={e => updateActionStatus(4)}
										action="check"
										variant="outlined"
										color="secondary"
										label={t`Mark complete`}
									/>
								)}
							</Fragment>
						)}
						{selectedAction.agent_user_id === app.users.current.id && (
							<Fragment>
								{selectedAction.status_id == 1 && (
									<ActionButton
										loading={loadingActionStatus}
										onClick={e => updateActionStatus(2)}
										action="check"
										variant="outlined"
										color="primary"
										label={t`Mark accepted`}
										dataTrackEvent="action_action_accepted"
									/>
								)}
								{selectedAction.status_id == 2 && (
									<ActionButton
										loading={loadingActionStatus}
										onClick={e => updateActionStatus(3)}
										action="check"
										variant="outlined"
										color="secondary"
										label={t`Mark done`}
										dataTrackEvent="action_action_mark_done"
									/>
								)}
								{selectedAction.status_id != 4 && selectedAction.status_id != 5 && (
									<ActionIconButton
										tooltip={t`Decline action`}
										action="close"
										loading={loadingActionStatus}
										onClick={() => updateActionStatus(5)}
										dataTrackEvent="action_action_declined"
									/>
								)}
							</Fragment>
						)}
						<ActionIconButton
							tooltip={t`Edit action`}
							action="edit"
							onClick={() => setActionDialogs('edit')}
						/>
					</Fragment>
				)}
				<ActionIconButton
					tooltip={t`Reassign actions`}
					action="reassign"
					onClick={() => setActionDialogs('reassign')}
				/>
				<ActionIconButton
					tooltip={t`Add tags`}
					action="tags"
					onClick={() => setActionDialogs('tags')}
				/>

				<ActionIconButton
					tooltip={t`Delete actions`}
					action="delete"
					onClick={() => setActionDialogs('delete')}
					dataTrackEvent="action_action_deleted"
				/>
			</SelectionAppBar>

			<ActionActionDialogs
				open={state.dialogOpen}
				onClose={() => setActionDialogs('')}
				onDelete={() => toggleSelectAll(false)}
				ids={state.selected}
				loadData={loadData}
			/>
		</Fragment>
	);
}
