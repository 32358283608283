import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import Switch from '@material-ui/core/Switch';
import Icon from '@material-ui/core/Icon';

import { makeStyles } from '@/styles';

import {
	EditableContent,
	List,
	ListSubheader,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	right: {
		flex: '0 0 auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	input: {
		height: theme.spacing(8.5),
	},
	editIconButton: {
		verticalAlign: 'middle',
		marginLeft: theme.spacing(0.5),
	},
	wrap: {
		display: props => props.display,
		width: props => (props.fullWidth ? '100%' : 'auto'),
		transition: theme.transitions.create('backgroundColor'),
		borderTopLeftRadius: theme.shape.borderRadius,
		borderTopRightRadius: theme.shape.borderRadius,
		'&:hover': {
			backgroundColor: props =>
				!props.disableHighlight && props.editable ? 'rgba(0,0,0,.09)' : 'transparent',
		},
	},
	edit: {
		backgroundColor: props =>
			!props.disableHighlight && props.editable ? 'rgba(0,0,0,.09)' : 'transparent',
	},
}));

function ListSwitch({ icon, title, value, checked, editable, onChange = () => {} }) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<ListItem
			button={editable}
			className={classes.input}
		>
			{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
			<ListItemText
				primary={t(value)}
				secondary={title}
				onClick={e => {
					if (editable) {
						onChange(!checked);
					}
				}}
			/>
			<ListItemSecondaryAction>
				<Switch
					edge="end"
					onChange={e => {
						if (editable) {
							onChange(!checked);
						}
					}}
					checked={checked}
					inputProps={{ 'aria-labelledby': 'alerts-switch' }}
				/>
			</ListItemSecondaryAction>
		</ListItem>
	);
}

function ListItemCard({ header, listItems, initial, handlePost, setFormValue }) {
	const { t } = useTranslation();

	return (
		<List>
			{header && <ListSubheader>{header}</ListSubheader>}
			{listItems.map((item, index) => {
				const {
					target = '',
					icon,
					title,
					value,
					editable = true,
					checked = false,
					options = [],
					showErrors = false,
					rules,
					messages,
					dataTestElement = '',
					dataTrackEvent = '',
				} = item;
				const key = `${title.replace(' ', '_')}_${index}`;

				return (
					<Fragment key={key}>
						{item.type === 'switch' && (
							<ListSwitch
								key={key}
								icon={<Icon>{icon}</Icon>}
								name={title}
								title={t(title)}
								value={value}
								checked={checked}
								editable={editable}
								dataTestElement={dataTestElement}
								onChange={value => {
									//value yes/no bit unhandy
									if (target === 'alerts') {
										setFormValue(target, value === true ? 'yes' : 'no');
									} else {
										setFormValue(target, value ?? '');
									}
								}}
							/>
						)}
						{item.type !== 'switch' && (
							<ListItem>
								{icon && <ListItemIcon>{<Icon>{icon}</Icon>}</ListItemIcon>}
								<ListItemText
									primary={
										<EditableContent
											editable={editable}
											showIconAlways
											showErrors={showErrors}
											rules={rules}
											messages={messages}
											options={options}
											onClose={valid => {
												if (valid) {
													handlePost(target);
												}
												if (!valid) {
													setFormValue(target, initial[target]);
												}
											}}
											onEdit={() => {
												setFormValue(target, value ?? '');
											}}
											onChange={value => {
												setFormValue(target, value ?? '');
											}}
											value={value ?? ''}
										>
											{(options.length > 0
												? options.find(val => val.value == value)?.label
												: value) || t`Not set`}
										</EditableContent>
									}
									secondary={title}
								/>
							</ListItem>
						)}
					</Fragment>
				);
			})}
		</List>
	);
}

export default ListItemCard;
