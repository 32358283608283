import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { smart_recaps_gif } from '@/img';
import { AiIcon } from '@/components/Icons';

import useDashboard from './useDashboard';
import { useDashboardContext } from './DashboardContextProvider';
import { makeStyles } from '@/styles';
import { PageHeader, EmptyStateHandleHasFeedback } from '@/components/App';

import {
	Loader,
	ActionButton,
	Grid,
	Card,
	CardContent,
	CardActions,
	Typography,
} from '@/components/Layout';
import DashboardAdditDialog from './DashboardAdditDialog';
import DashboardOverviewRow from './DashboardOverviewRow';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';
import { useAppContext } from '@/components/AppContext';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(2),
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
	wrap: {
		flexGrow: 1,
		height: '100%',
		maxWidth: '100%',
	},
	marginBottom: {
		marginBottom: theme.spacing(2),
	},
	directInsightsImg: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	aiIcon: {
		color: '#ffac33',
	},
}));

export default function DashboardOverview() {
	const { t } = useTranslation();
	const classes = useStyles();
	const hasEditPermission = useHasPermission();

	const location = useLocation();
	const history = useHistory();

	const {
		dashboards,
		loading,
		loadDashboards,
		setCurrentDashboardId,
		currentDashboardId,
	} = useDashboardContext();

	const {
		currentDashboard,
		dashboardApi: { dispatch },
	} = useDashboard();

	useEffect(() => {
		if (loading || dashboards.length === 0) {
			return;
		}

		if (location.state?.from === 'list') {
			return setCurrentDashboardId(undefined);
		}

		if (currentDashboardId && currentDashboard) {
			history.push(`/reporting/dashboard/${currentDashboardId}`);
		}
	}, [currentDashboardId, location.state?.from, currentDashboard, dashboards]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const setNewDashboard = id => {
		dispatch({
			type: 'set_new_dashboard',
			payload: {
				id: id,
			},
		});
	};

	return (
		<>
			<PageHeader title={t`reporting-dashboard_overview-pageheader-overview`} />
			{loading && (
				<Loader
					empty={dashboards.length === 0}
					appBar={dashboards.length > 0}
				/>
			)}
			{!loading && dashboards.length === 0 && (
				<EmptyStateHandleHasFeedback
					className={classes.emptyState}
					image={<img src={`/assets/img/r/emptystate/dashboard_no_charts.gif`} />}
					primaryNoSurvey={t`reporting-dashboard_overview-emptystate_no_survey-title`}
					secondaryNoSurvey={t`reporting-dashboard_overview-emptystate_no_survey-text`}
					primary={t`reporting-dashboard_overview-emptystate_default-title`}
					secondary={t`reporting-dashboard_overview-emptystate_default-text`}
					action={
						<NoPermissionTooltip>
							<ActionButton
								variant="contained"
								color="primary"
								onClick={() => setDialogOpen(true)}
								data-test-element="dashboard-overview-create-new"
								disabled={!hasEditPermission}
							>
								{t`reporting-dashboard_overview-emptystate_default-button_create_dashboard`}
							</ActionButton>
						</NoPermissionTooltip>
					}
				/>
			)}
			{dashboards.length > 0 && (
				<>
					<Grid
						container
						direction="column"
						className={classes.marginBottom}
					>
						<Grid
							item
							xs={12}
						>
							<ActionButton
								action="add"
								onClick={() => {
									setDialogOpen(true);
								}}
								data-test-element="dashboard-overview-create-new"
								disabled={!hasEditPermission}
							>
								{t`reporting-dashboard_overview-button-create_new_dashboard`}
							</ActionButton>
						</Grid>
					</Grid>

					<Grid
						container
						direction="row"
						spacing={2}
					>
						<Grid
							item
							sm={12}
							md={9}
							xl={10}
						>
							<Grid
								container
								direction="column"
								spacing={2}
							>
								{dashboards.map((dashboard, index) => {
									return (
										<Grid
											item
											key={index}
											xs={12}
										>
											<DashboardOverviewRow dashboard={dashboard} />
										</Grid>
									);
								})}
							</Grid>
						</Grid>
						<Grid
							item
							sm={12}
							md={3}
							xl={2}
						>
							<Card>
								<img
									src={smart_recaps_gif}
									className={classes.directInsightsImg}
								/>
								<CardContent>
									<Typography
										component="p"
										className={classes.titleMargin}
										variant="subtitle1"
										fontWeight="medium"
										gutterBottom
									>
										{t`reporting-dashboard-overview-go_to_smart_recaps-title`}
										<AiIcon className={classes.aiIcon} />
									</Typography>
									<Typography
										component="p"
										variant="body1"
										gutterBottom
									>
										{t`reporting-dashboard-overview-go_to_smart_recaps-text`}
									</Typography>
								</CardContent>
								<CardActions>
									<ActionButton
										variant="outlined"
										color="primary"
										link
										to="/reporting/smart-recaps"
									>
										{t`reporting-dashboard-overview-go_to_smart_recaps-button`}
									</ActionButton>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</>
			)}
			<DashboardAdditDialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				loadDashboards={loadDashboards}
				setNewDashboard={setNewDashboard}
			/>
		</>
	);
}
