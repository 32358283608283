import React, { useState, useEffect, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useSnackbar } from 'notistack';
import { useImmerReducer } from 'use-immer';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ContainerQuery } from 'react-container-query';

import { useAppContext } from '@/components/AppContext';
import { AppBarActions, PageHeader } from '@/components/App';
import {
	LayoutWithSidebar,
	constants,
	EmptyState,
	Loader,
	EditableContent,
	ActionIconButton,
	ActionButton,
	Box,
	Grid,
	Typography,
	FormattedDate,
	SplitButton,
	Chip,
} from '@/components/Layout';
import { ProductNotInPlanDialog } from '@/components/Billing';
import { useOnboardingContext } from '@/components/Onboarding';
import { CheckIcon } from '@/components/Icons';
import { tryParse } from '@/utils';
import { format } from '@/utils/date';
import {
	useHasPermission,
	permissionSubjects,
	useHandleNoAccessRedirect,
} from '@/components/Permission';
import { FeatureToggle } from '@/components/FeatureToggle';

import DeploymentEditorSidebarContent from './DeploymentEditorSidebarContent';
import DeploymentEditorItem from './DeploymentEditorItem';
import DeploymentCodeDialog from './DeploymentCodeDialog';
import DeploymentVersionDialog from './DeploymentVersionDialog';
import DeploymentDraftPendingDialog from './DeploymentDraftPendingDialog';
import DeploymentPublishDialog from './DeploymentPublishDialog';
import DeploymentAssistantDialog from './DeploymentAssistantDialog';
import DeploymentDraftFoundDialog from './DeploymentDraftFoundDialog';
import { DeploymentStoreProvider, useDeploymentStore } from './DeploymentStore';
import DeploymentPublishSetting from './DeploymentPublishSetting';
import DeploymentVersioningIntroductionTour from './DeploymentVersioningIntroductionTour';
const useStyles = makeStyles(theme => ({
	deploymentAreaWrap: {
		minWidth: '95%',
		maxWidth: '95%',
		minHeight: '85%',
		height: '100%',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '7.5%',
		paddingRight: '7.5%',
	},
	deploymentAreaWrapSm: {
		paddingLeft: '5%',
		paddingRight: '5%',
	},
	deploymentAreaWrapXs: {
		paddingLeft: '0',
		paddingRight: '0',
	},
	deploymentArea: {
		position: 'relative',
		flexGrow: 1,
		borderRadius: theme.shape.borderRadius,
		border: `3px dashed ${theme.palette.grey[300]}`,
		padding: theme.spacing(1),
	},
	centerContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selectRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	spaceChildren: {
		display: 'flex',
		'& > *': {
			marginRight: theme.spacing(0.5),
		},
	},
	draftChip: {
		marginLeft: theme.spacing(1),
	},
}));

function simpleReducer(state, action) {
	switch (action.type) {
		case 'set_selected':
			state.selectedRule = action.payload.id;
			state.activeTab = 1;
			return;

		case 'set_tab':
			state.activeTab = action.payload;
			return;

		case 'set_save_menu_anchor':
			state.saveMenuAnchor = action.payload;
			return;

		case 'set_code_dialog':
			state.codeDialogOpen = action.payload;
			return;

		case 'set_assistant_dialog':
			state.assistantDialogOpen = action.payload;
			return;

		case 'set_version_dialog':
			state.versionDialogOpen = action.payload;
			return;

		case 'set_publish_dialog':
			state.publishDialogOpen = action.payload;
			return;

		default:
			return;
	}
}

export default function DeploymentEditor({
	deploymentId: deploymentIdFromProps,
	editorType: editorTypeFromProps,
	...props
}) {
	const { deploymentId: deploymentIdFromUrl, editorType: editorTypeFromUrl } =
		useParams();
	const deploymentId = deploymentIdFromProps ?? deploymentIdFromUrl;
	const editorType = editorTypeFromProps ?? editorTypeFromUrl;

	return (
		<DeploymentStoreProvider
			deploymentId={deploymentId}
			editorType={editorType}
		>
			<DeploymentEditorInner
				deploymentIdFromProps={deploymentIdFromProps}
				deploymentIdFromUrl={deploymentIdFromUrl}
				deploymentId={deploymentId}
				editorTypeFromProps={editorTypeFromProps}
				editorTypeFromUrl={editorTypeFromUrl}
				editorType={editorType}
				{...props}
			/>
		</DeploymentStoreProvider>
	);
}

function DeploymentEditorInner({
	AppBarActionsContainer,
	type,
	inDrawer,
	deploymentId,
	deploymentIdFromProps,
	deploymentIdFromUrl,
	editorType,
	editorTypeFromProps,
	editorTypeFromUrl,
	...props
}) {
	const { app } = useAppContext();
	const location = useLocation();
	const onboarding = useOnboardingContext();
	const history = useHistory();
	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.deployments,
	});

	const { t } = useTranslation();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const {
		getCurrent,
		getVersions,
		getDraft,
		getSurveys,
		addRule,
		moveRule,
		deleteRule,
		setName,
		saveDeployment,
	} = useDeploymentStore(store => store.actions);

	const currentDeployment = useDeploymentStore(store => store.current);
	const loadingCurrent = useDeploymentStore(store => store.loading);
	const loadingSurveys = useDeploymentStore(store => store.loadingSurveys);
	const saveDeploymentLoading = useDeploymentStore(store => store.saveDeploymentLoading);
	const saveDrafLoading = useDeploymentStore(store => store.saveDrafLoading);
	const surveysByKey = useDeploymentStore(store => store.surveysByKey);
	const pendingDraftChanges = useDeploymentStore(store => store.pendingDraftChanges);

	const workingDraftName = useDeploymentStore(store => store.workingDraft.name);
	const workingDraftDateTime = useDeploymentStore(store => store.workingDraft.datetime);
	const workingDraftRevision = useDeploymentStore(store => store.workingDraft.revision);
	const workingDraftRules = useDeploymentStore(store => store.workingDraft.rules ?? []);

	//initialize store
	useEffect(function initStore() {
		getCurrent();
		getVersions();
		getDraft();
		getSurveys();
	}, []);

	useHandleNoAccessRedirect({
		groups: currentDeployment?.permission_groups,
		to: '/data-collection/deployment',
	});

	useEffect(() => {
		if (
			!loadingCurrent &&
			currentDeployment?.type &&
			currentDeployment?.type !== editorTypeFromUrl &&
			!editorTypeFromProps
		) {
			history.push(
				`/data-collection/deployment/edit/${deploymentId}/${currentDeployment.type}`
			);
		}
	}, [
		currentDeployment,
		loadingCurrent,
		editorTypeFromProps,
		editorTypeFromUrl,
		deploymentId,
		history,
	]);

	useEffect(() => {
		if (loadingCurrent || saveDeploymentLoading) {
			return;
		}

		if (app.session.onboarding_trial) {
			return;
		}

		if (workingDraftRevision === 'draft') {
			enqueueSnackbar(t`data_collection-deployment_editor-version_snackbar-draft`);
		}

		if (!currentDeployment.datetime) {
			return;
		}

		if (currentDeployment?.revision === workingDraftRevision) {
			enqueueSnackbar(
				t(`data_collection-deployment_editor-version_snackbar-live`, {
					date: format(new Date(currentDeployment.datetime ?? null), 'dd-MM-yyyy'),
				})
			);
		}

		if (
			currentDeployment?.revision !== workingDraftRevision &&
			workingDraftRevision !== 'draft'
		) {
			enqueueSnackbar(
				t(`data_collection-deployment_editor-version_snackbar-other`, {
					date: format(new Date(currentDeployment.datetime), 'dd-MM-yyyy'),
				})
			);
		}
	}, [
		workingDraftRevision,
		currentDeployment,
		enqueueSnackbar,
		t,
		app.session.onboarding_trial,
		loadingCurrent,
		saveDeploymentLoading,
	]);

	const [showProductNotAllowedDialog, setShowProductNotAllowedDialog] = useState(false);

	useEffect(() => {
		if (editorType) {
			setShowProductNotAllowedDialog(!app.api.packageProductAllowed(editorType));
		}
	}, [editorType, app]);

	const [simpleState, dispatchSimple] = useImmerReducer(simpleReducer, {
		selectedRule: null,
		activeTab: 0,
		surveyMapBySurveyKey: {},
		deploymentId,
		editorType,
		codeDialogOpen: false,
	});

	function onDragEnd(result) {
		const { source, destination, draggableId } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === 'deployment-area') {
			if (source.droppableId === destination.droppableId) {
				moveRule({
					destinationIndex: destination.index,
					sourceIndex: source.index,
				});
			} else if (source.droppableId !== destination.droppableId) {
				const survey = surveysByKey[draggableId];
				const advanced = tryParse(survey.advanced, {});
				const isSdk = survey.campaign === 'sdk' || advanced.sdk_survey;
				addRule({
					destinationIndex: destination.index,
					rule: {
						surveyKey: survey.survey_key,
						webview: survey.sdk_type === 'webview',
						domain: app.domain,
						editorType,
						surveyType: isSdk ? 'sdk' : 'web',
						surveyFormat: survey.survey_format ?? 'form',
					},
				});
			}
		}
	}

	function selectRule(id, index) {
		dispatchSimple({
			type: 'set_selected',
			payload: {
				id: simpleState.selectedRule !== id ? id : null,
			},
		});
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			{!inDrawer && (
				<PageHeader
					title={
						<Box display="inline-flex">
							<Box display="inline-flex">
								<Box display="inline">{t`Edit deployment`}</Box>
								<Box
									display="inline"
									mx={0.5}
								>
									-
								</Box>
								<EditableContent
									value={workingDraftName}
									display="inline"
									onChange={value => setName(value)}
									editable={hasEditPermission}
									showIconAlways
								>
									{workingDraftName}
								</EditableContent>
							</Box>
							{workingDraftRevision === currentDeployment.revision && (
								<Box
									display="inline-flex"
									alignItems="center"
								>
									<Chip
										label={t`data_collection-deployment_editor-live_chip`}
										className={classes.draftChip}
										color="secondary"
									/>
								</Box>
							)}
							{workingDraftRevision === 'draft' && (
								<Box
									display="inline-flex"
									alignItems="center"
								>
									<Chip
										label={t`data_collection-deployment_editor-draft_chip`}
										className={classes.draftChip}
									/>
									{!pendingDraftChanges && (
										<>
											<Box
												clone
												ml={1}
												mr={0.5}
												color="text.secondary"
											>
												<CheckIcon
													fontSize="small"
													color="inherit"
												/>
											</Box>
											<Typography
												fontSize="small"
												color="textSecondary"
												variant="caption"
											>
												{t`data_collection-deployment_editor-fully_saved`}
											</Typography>
										</>
									)}

									{saveDrafLoading && (
										<Box
											display="inline-flex"
											ml={1}
										>
											<Loader
												size={12}
												circular
											/>
										</Box>
									)}
								</Box>
							)}
						</Box>
					}
					documentTitle={`${t`Edit deployment`} ${workingDraftName}`}
					backTo="/data-collection/deployment"
					backTooltip={t`To deployments`}
				/>
			)}
			<AppBarActions container={AppBarActionsContainer}>
				<>
					<Box textAlign="center">
						<Box
							className={classes.spaceChildren}
							data-onboarding="deploy-buttons"
							mb={0.25}
						>
							{editorType === 'web' && !app.session.onboarding_trial && (
								<ActionButton
									action="assistant"
									tooltip={t`data_collection-deployment_editor-assistant_button-tooltip`}
									onClick={e =>
										dispatchSimple({
											type: 'set_assistant_dialog',
											payload: true,
										})
									}
									dataTrackEvent="deployment_editor_open_deployment_assistant_dialog"
								>
									{t`data_collection-deployment_editor-assistant_button-label`}
								</ActionButton>
							)}
							<SplitButton
								action="publish"
								className={classes.saveButton}
								loading={loadingCurrent || saveDeploymentLoading}
								disabled={
									!hasEditPermission ||
									workingDraftRevision === currentDeployment?.revision ||
									(app.session.onboarding_trial && workingDraftRules === 0)
								}
								data-test-element="deployment-editor-save-button"
								data-track-event={
									app.session.onboarding_trial
										? 'onboarding_trial_step_deployment_save_deployment'
										: 'deployment_editor_save_button'
								}
								options={
									app.session.onboarding_trial
										? [
												{
													label: t`data_collection-deployment-editor-header_trial-button_save`,
													onClick: () =>
														saveDeployment(response => {
															if (response.code !== 200) {
																enqueueSnackbar(
																	t`data_collection-deployments-snackbar-save_error`
																);
																return;
															}
															enqueueSnackbar(
																t`data_collection-deployment_editor-publish_dialog-save_snackbar`
															);

															onboarding.dispatch({
																type: 'set_trial_step',
																payload: {
																	url: `/data-collection/deployment/list`,
																	from: location.pathname,
																},
															});
														}),
												},
										  ]
										: [
												{
													label: t`data_collection-deployment_editor-publish_button`,
													onClick: () =>
														dispatchSimple({
															type: 'set_publish_dialog',
															payload: true,
														}),
												},
												{
													label: t`data_collection-deployment_editor-get_code-label`,
													dataTrackEvent: 'deployment_editor-get_code_button',
													onClick: () => {
														dispatchSimple({
															type: 'set_code_dialog',
															payload: true,
														});
													},
												},
										  ]
								}
							/>
						</Box>
					</Box>
				</>
			</AppBarActions>
			<FeatureToggle feature="deployment_versioning_introduction_tour">
				{!app.session.onboarding_trial && <DeploymentVersioningIntroductionTour />}
			</FeatureToggle>
			<ContainerQuery
				query={{
					sm: { maxWidth: 900 },
				}}
			>
				{topMatches => (
					<LayoutWithSidebar
						editor
						drawerOpen
						persistentDrawer
						drawerWidth={
							simpleState.activeTab === 1
								? '50%'
								: topMatches.sm
								? constants.deploymentEditorDrawerWidthSm
								: constants.deploymentEditorDrawerWidth
						}
						drawerContent={
							<DeploymentEditorSidebarContent
								state={simpleState}
								dispatch={dispatchSimple}
								editorType={editorType}
							/>
						}
						DrawerProps={{
							'data-onboarding': 'deployment-sidebar',
						}}
					>
						<ContainerQuery
							query={{
								xs: { maxWidth: 400 },
								sm: { maxWidth: 600 },
							}}
						>
							{matches => (
								<div
									data-onboarding="build-area"
									data-test-element="deployment-editor-build-area"
									className={`${classes.deploymentAreaWrap} ${
										matches.sm ? classes.deploymentAreaWrapSm : ''
									} ${matches.xs ? classes.deploymentAreaWrapXs : ''}`}
								>
									<Box mb={1}>
										<Grid
											container
											direction="row"
											alignItems="center"
										>
											<Grid
												item
												xs
											>
												<ActionButton
													action="expand_more"
													onClick={() =>
														dispatchSimple({
															type: 'set_version_dialog',
															payload: true,
														})
													}
													disableTextTransform
													loading={loadingCurrent}
													data-onboarding="version-toggle"
													data-track-event="deployment_editor_open_version_dialog"
												>
													<Trans
														i18nKey={`data_collection-deployment_editor-open_version_dialog_button-${
															workingDraftRevision === 'draft' ? 'draft' : 'version'
														}`}
														components={{
															date: workingDraftDateTime ? (
																<FormattedDate date={workingDraftDateTime} />
															) : (
																t`data_collection-deployment_editor-current_version_no_date`
															),
														}}
													/>
												</ActionButton>
											</Grid>
											<Grid item>
												<DeploymentPublishSetting />
											</Grid>
										</Grid>
									</Box>

									<Droppable droppableId="deployment-area">
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.droppableProps}
												className={`${classes.deploymentArea} ${
													matches.small ? classes.deploymentAreaSmall : ''
												} ${workingDraftRules.length === 0 ? classes.centerContent : ''}`}
											>
												{workingDraftRules.length === 0 &&
													!snapshot.isDragging &&
													!snapshot.isDraggingOver &&
													!loadingCurrent && (
														<Box
															height="100%"
															width="100%"
															position="absolute"
															top={0}
															left={0}
															right={0}
															bottom={0}
														>
															<EmptyState
																primary={t`data_collection-deployments-emptystate_detail-title`}
																secondary={t`data_collection-deployments-emptystate_detail-text`}
															/>
														</Box>
													)}
												{loadingCurrent && (
													<Loader empty={workingDraftRules.length === 0} />
												)}
												{workingDraftRules.map((rule, index) => {
													const form = surveysByKey[rule.then[0].args[0]];

													return (
														<DeploymentEditorItem
															startOpen={workingDraftRules.length < 4}
															onClick={e => selectRule(rule.id, index)}
															key={rule.id}
															form={form}
															rule={rule}
															index={index}
															loading={loadingCurrent || loadingSurveys}
															selected={simpleState.selectedRule === rule.id}
															onDelete={() => {
																deleteRule({
																	index,
																	ruleId: rule.id,
																});
															}}
															editorType={editorType}
														/>
													);
												})}

												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</div>
							)}
						</ContainerQuery>
					</LayoutWithSidebar>
				)}
			</ContainerQuery>
			<DeploymentCodeDialog
				id={deploymentId}
				variant={editorType}
				open={simpleState.codeDialogOpen}
				onClose={() =>
					dispatchSimple({
						type: 'set_code_dialog',
						payload: false,
					})
				}
			/>

			<DeploymentVersionDialog
				open={simpleState.versionDialogOpen}
				onClose={() =>
					dispatchSimple({
						type: 'set_version_dialog',
						payload: false,
					})
				}
			/>

			<ProductNotInPlanDialog
				product={editorType}
				open={showProductNotAllowedDialog}
				backTo="/data-collection/deployment"
			/>

			<DeploymentDraftPendingDialog />

			<DeploymentPublishDialog
				open={simpleState.publishDialogOpen}
				onClose={() =>
					dispatchSimple({
						type: 'set_publish_dialog',
						payload: false,
					})
				}
			/>

			<DeploymentAssistantDialog
				name={currentDeployment?.name}
				token={currentDeployment?.token}
				open={simpleState.assistantDialogOpen}
				onClose={() =>
					dispatchSimple({
						type: 'set_assistant_dialog',
						payload: false,
					})
				}
			/>

			<DeploymentDraftFoundDialog />
		</DragDropContext>
	);
}
