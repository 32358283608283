import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

import { useTranslation } from 'react-i18next';

import { Link } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	link: {
		cursor: 'pointer',
		textDecoration: 'underline',
		verticalAlign: props => props.verticalAlign ?? 'middle',
		fontWeight: 400,
		'& svg': {
			fontSize: 'inherit',
			marginLeft: theme.spacing(0.5),
			verticalAlign: 'middle',
		},
	},
}));

const pageMap = {
	auto_tags: '506691856/How+do+I+add+tags+labels+automatically+automated+tagging',
	tags_mobot:
		'506691856/How+do+I+add+tags+labels+automatically+automated+tagging#A.I.-tagging',
	deployments: '788856935/Deployment+help+articles',
	dashboard: '800489490/Dashboard+help+articles',
	direct_insights: '1741389828/Direct+insights',
	feedback_inbox: '797048875/Reporting+Inbox+help+articles',
	access_groups: '1698693139/Access+groups',
	chart_editor_multiple_series: '1543176204/Checkbox+chart+example',
	smart_recaps: '2297888786/Smart+recaps',
};

export default function KnowledgeBaseLink({
	className = '',
	children,
	page,
	dataTrackEvent,
	variant,
	...props
}) {
	const classes = useStyles(props);
	const { t } = useTranslation();

	return (
		<Link
			tooltip={t`tooltip_knowledgebase`}
			className={`${classes.link} ${className}`}
			dataTrackEvent={dataTrackEvent}
			target="_blank"
			variant={variant}
			rel="noopener"
			href={`https://mopinion.atlassian.net/wiki/spaces/KB/pages/${pageMap[page]}`}
			{...props}
		>
			{children}
			<LaunchIcon />
		</Link>
	);
}
