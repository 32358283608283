import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import { useAppContext } from '../AppContext';
import { useListFilterContext, FilterGroup, filterUtils } from '../FilteredLists';
import { UserAutoComplete } from '../AutoComplete';
import { useTagContext } from '../Tags';
import { useTranslation } from 'react-i18next';

function CustomerListFilters({ ...props }) {
	const { app } = useAppContext();
	const { setSingleFilter, filters, other, setMultiFilter } = useListFilterContext();
	const { tags } = useTagContext();
	const { t } = useTranslation();

	const mappedTags = tags.tagList.map(tag => {
		return {
			value: tag,
			label: tag,
		};
	});

	return (
		<Fragment>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Type to start searching')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					value={filters.search ?? ''}
					onChange={e => setSingleFilter('search', e.target.value)}
				/>
			</div>

			<div>
				<UserAutoComplete
					variant="filled"
					fullWidth
					label={t`Owner`}
					placeholder={t`John Doe...`}
					multiple
					selectedItem={
						filters.assigners &&
						filters.assigners.map(ownerFilter => {
							return {
								id: ownerFilter,
							};
						})
					}
					onChange={users => {
						setMultiFilter(
							'assigners',
							users.map(user => user.id)
						);
					}}
				/>
			</div>

			<div>
				<UserAutoComplete
					variant="filled"
					fullWidth
					label={t`Assignee`}
					placeholder={t`John Doe...`}
					multiple
					selectedItem={
						filters.assignees &&
						filters.assignees.map(assigneeFilter => {
							return {
								id: assigneeFilter,
							};
						})
					}
					onChange={users => {
						setMultiFilter(
							'assignees',
							users.map(user => user.id)
						);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Tags')}
					filterKey={'tags'}
					filters={[
						{ label: t('No tags'), value: 'withouttags' },
						{ label: t('All tags'), value: 'all_tags' },
						...mappedTags,
					].map(filter => {
						if ((filters.tags || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					autoComplete={mappedTags}
					favorites={tags.tagCount ?? {}}
					onChange={({ key, value }) => {
						if (value.indexOf('all_tags') > -1) {
							value = mappedTags.map(tag => tag.value);
						} else if (value.indexOf('withouttags') > -1) {
							value = ['withouttags'];
						}
						setMultiFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t`Action types`}
					filterKey="actiontypes"
					filters={app.actions.types.map(actionType => ({
						label: actionType.name,
						value: actionType.id,
						...((filters.actiontypes || []).indexOf(actionType.id) > -1 && {
							selected: true,
						}),
					}))}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t`Action status`}
					filterKey="actionstatuses"
					filters={app.actions.status
						.filter(actionStatus => Number(actionStatus.id) !== 6)
						.map(actionStatus => ({
							label: t(
								`actions-manage-filters-action_status-${actionStatus.name.toLowerCase()}`
							),
							value: actionStatus.id,
							...((filters.actionstatuses || []).indexOf(actionStatus.id) > -1 && {
								selected: true,
							}),
						}))}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>
		</Fragment>
	);
}

export default CustomerListFilters;
